import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "react-intl";
import CookieConsent from "react-cookie-consent";
import Link from "./link";

const query = graphql`
  query {
    legal: allContentfulPage(
      filter: { contentful_id: { eq: "4MqaAmsD225xUL2r4LOWwj" } }
    ) {
      nodes {
        node_locale
        slug
      }
    }
  }
`;

const CookieText = () => {
  const { legal } = useStaticQuery(query);
  const { locale } = useIntl();
  const isActiveLocale = ({ node_locale }) => node_locale === locale;
  const legalPage = legal.nodes.find(isActiveLocale);

  if (locale === "en") {
    return (
      <>
        We use Google Analytics to track the origin of visitors to our site.
        <br />
        If you do not wish to do so, you can refuse the cookies.{" "}
        <Link href={`/${legalPage.slug}/`}>About cookies</Link>
      </>
    );
  }

  return (
    <>
      Nous utilisons les solutions Google Analytics pour connaître l’origine des
      visiteurs de notre site.
      <br />
      Si vous ne le souhaitez pas, vous pouvez refuser les cookies.{" "}
      <Link href={`/${legalPage.slug}/`}>À propos des cookies</Link>
    </>
  );
};

const CookieBar = () => {
  const { formatMessage } = useIntl();

  return (
    <CookieConsent
      disableStyles
      enableDeclineButton
      overlay
      overlayClasses="cookie-bar"
      containerClasses="container"
      contentClasses="cookie-bar-content"
      buttonWrapperClasses="cookie-bar-actions"
      buttonClasses="link"
      declineButtonClasses="link"
      buttonText={formatMessage({ id: "cookiesAccept" })}
      declineButtonText={formatMessage({ id: "cookiesDecline" })}
      ariaAcceptLabel={formatMessage({ id: "cookiesAccept" })}
      ariaDeclineLabel={formatMessage({ id: "cookiesDecline" })}
    >
      <CookieText />
    </CookieConsent>
  );
};

export default CookieBar;
